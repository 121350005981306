@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-top: 4px solid #203c54; /* dark-blue-custom */
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

.loader.small {
  width: 16px;
  height: 16px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Add this to your global styles or a specific CSS file */
@media (max-width: 1024px) {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 50;
    width: 100%;
    max-width: 16rem; /* 64 / 16 = 4rem */
    background-color: #203c54; /* dark-blue-custom */
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }

  .sidebar-open {
    transform: translateX(0);
  }
}

/* Hide number input arrows */
.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-arrows {
  -moz-appearance: textfield; /* Firefox */
}

.modal-overlay {
  background-color: rgba(0, 0, 0, 0.75);
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  max-height: 90%;
  background: white;
  padding: 20px;
  border-radius: 8px;
  overflow: auto;
}

.modal img {
  max-width: 100%;
  max-height: 80vh;
}

.modal button {
  z-index: 10;
}

.masonry-grid {
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}

.masonry-grid_column {
  padding-left: 1px; /* gutter size */
  background-clip: padding-box;
}

.widget {
  margin-bottom: 1px;
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
}

.ads-section {
  max-height: 150px; /* Adjust as needed */
  margin-top: 20px;
  text-align: center;
}

.adsbygoogle {
  display: block;
  margin: auto;
}
