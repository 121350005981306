/* TierComparisonTable.css */
.tier-comparison-table th,
.tier-comparison-table td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
}

.tier-comparison-table th {
  background-color: #f4f4f4;
  text-align: center;
}

.tier-comparison-table td {
  vertical-align: middle;
}

.tier-comparison-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.tier-comparison-table .text-green-500 {
  color: #48bb78;
}

.tier-comparison-table .text-red-500 {
  color: #f56565;
}